<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" style="flex: 0" dark elevation="1" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text
        class="pt-2 pb-0"
        style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 170px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true"
                        @userChange="onDateChange"
            />
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true" />
          </div>
          <div style="flex: 0 0 300px; margin-right: 16px">
            <select_input
                v-model="subdivision_id"
                :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                :computed-getter="'get_subdivision_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Підрозділ"
                :show-select-on-focus="false"
                :select-button-object="{
                              name: 'SUBDIVISION_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список підрозділ',
                              element_title: 'Підрозділ',
                              show: true
                            }"
                @selectChange="onDateChange"
            />
          </div>
        </div>
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 326px; margin-right: 16px">
            <select_input
                v-model="warehouse_id"
                :computed-action="'SELECT_WAREHOUSE_LIST_ELEMENTS'"
                :computed-getter="'get_warehouse_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Склад"
                :show-select-on-focus="false"
                :sync-action-props="true"
                :select-button-object="{
                                name: 'WAREHOUSE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список складів',
                                element_title: 'Склад',
                                show: true
                              }"
                @selectChange="onDateChange"
            />
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  МНМА
                </v-tab>
                <v-tab>
                  Друк
                </v-tab>

                <v-tab-item class="pt-2">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Од. виміру
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            К-сть
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 240px">
                            <div class="document-table-col">
                              Відповідальна особа
                            </div>
                            <div class="document-table-col">
                              Спосіб відобр. витрат
                            </div>
                          </div>
                          <div class="document-table-col-group" style="flex: 0 0 240px">
                            <div class="document-table-col">
                              Рахунок обліку
                            </div>
                            <div class="document-table-col">
                              Термін кор. використ.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in table"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ table.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                              :computed-getter="'get_nomenclature_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              :action-props="{is_service: false}"
                              small
                              transparent
                              :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false},
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterNomenclatureSelect"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.unit_type_id"
                              :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                              :computed-getter="'get_unit_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :item-text="'additional_field_1'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                              :required="true"
                              @userChange="tableColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="true"
                                        @userChange="tableColChange"
                          />
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 240px">
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.response_person_id"
                                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                                :computed-getter="'get_personal_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :action-filter="'@field=type@;@start-with=S@full_time'"
                                :required="true"
                                small
                                transparent
                                :select-button-object="{
                            name: 'PERSONAL_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список працівників',
                            element_title: 'Працівник',
                            action_filter: '@field=person_type@;@start-with=S@full_time',
                            show: true
                          }"
                                @userChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.amortisation_calc_way"
                                :computed-action="'SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS'"
                                :computed-getter="'get_amortisation_calc_way_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                transparent
                                small
                                :show-select-on-focus="false"
                                :required="true"
                                :select-button-object="{
                            name: 'AMORTISATION_CALC_WAY_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список відображень витрат по амортизації',
                            element_title: 'Відобрадення витрати по амортизації',
                            show: true
                          }"
                                @userChange="tableColChange"
                            />
                          </div>
                        </div>
                        <div class="document-table-col-group" style="flex: 0 0 240px">
                          <div class="document-table-col">
                            <select_input
                                v-model="charge.chart_of_account_id"
                                :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                                :computed-getter="'get_chart_of_accounts_list_select'"
                                :input-value-as-value="false"
                                :item-search="'text'"
                                :show-on-focus="false"
                                :row_num="charge.row_num"
                                :show-select-on-focus="false"
                                :show-search-on-focus="true"
                                small
                                transparent
                                :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              show: true
                            }"
                                :required="true"
                                @userChange="tableColChange"
                            />
                          </div>
                          <div class="document-table-col">
                            <number_input :row_num="charge.row_num"
                                          v-model="charge.useful_life_period" v-integer transparent
                                          small field="count"
                                          :required="true"
                                          @userChange="tableColChange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pt-2">

                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
  UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
  REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
  CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_type_select_setting} from '@/utils/accounting'
import lowCostCommissioningAPI from "@/utils/axios/accounting/documents/low_cost_commissioning";
import {maxBy} from "lodash";
import {format_date_from_python} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component_wto";

const modalDeleteId = 'low_cost_commissioning_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_LowCostCommissioning",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    // textarea_input: () => import("@/components/accounting/input/document_text_area_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
  },
  data() {
    return {
      subconto_type_select_setting,
      subdivision_id: this.item.subdivision_id || null,
      warehouse_id: this.item.warehouse_id || null,
      table: [],
    }
  },
  methods: {
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.conducted = local_data.conducted || false
      this.subdivision_id = local_data.subdivision_id || null
      this.warehouse_id = local_data.warehouse_id || null
      this.table = []
    },
    fetch_data_by_id() {
      if (this.isNew && !this.item.copy_id) return
      if (this.isNew && this.item.copy_id) {
        lowCostCommissioningAPI.get_document_by_id(this.item.copy_id)
            .then(response => response.data)
            .then(data => {
              this.table = data.table.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
            })
      }

      if (!this.isNew) {
        lowCostCommissioningAPI.get_document_by_id(this.itemId)
            .then(response => response.data)
            .then(data => {
              this.conducted = data.conducted || false
              this.date = data.date || null
              this.number = data.number || null
              this.warehouse_id = data.warehouse_id || null
              this.subdivision_id = data.subdivision_id || null
              this.table = data.table.map((i, idx) => {
                return Object.assign({}, i, {row_num: idx + 1})
              })
            })
      }
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    afterNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service, unit_type_id
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
        unit_type_id = +(val.additional_field_2) || null
      } else {
        pdv = val.tax_type
        is_service = val.is_service
        unit_type_id = val.unit_type
      }

      if (val.row_num) {
        const row = this.table.find(item => item.row_num === val.row_num)
        if (row) {
          if (is_service) {
            this.$nextTick(() => {
              row.nomenclature_id = null
            })
            return;
          }
          row.tax_id = pdv
          row.unit_type_id = unit_type_id
          this.tableColChange(val)
        }
      }
    },
    onDateChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    tableColChange() {
      this.$nextTick(() => {
        this.checkDocument(false, true)
      })
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    addTableRow() {
      const charge_703_id = '221'
      const chart_703 = this.charts.find(c => c.type === charge_703_id) || {}

      this.table.push(
          {
            nomenclature_id: null,
            unit_type_id: null,
            response_person_id: null,
            count: 0,
            chart_of_account_id: chart_703.id,
            useful_life_period: 24,
            amortisation_calc_way: null,
            row_num: this.getNewRowNum('table'),
          }
      )
    },
    deleteTableRow(payload) {
      this.table.splice(this.table.indexOf(payload), 1)
    },

    clearDialog() {
      const payload = {
        text: `Очистити таблицю з МНМА?`,
        accept_button: true,
        id: 'clear_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearDocument() {
      this.table = []
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'warehouse_id': {id: 'warehouse_id', text: 'Не заповнений склад'},
        'subdivision_id': {id: 'subdivision_id', text: 'Не заповнений підрозділ'},
      }

      const table_ = {
        'nomenclature_id': {id: 'nomenclature_id', table: 'Товари', text: 'Не заповнена номенклатура'},
        'chart_of_account_id': {id: 'chart_of_account_id', table: 'Товари', text: 'Не заповнений рахунок доходу'},
        'count': {id: 'count', table: 'Товари', text: 'Не заповнена кількість'},
        'unit_type_id': {id: 'unit_type_id', table: 'Товари', text: 'Не заповнена одиниця виміру'},
        'amortisation_calc_way': {id: 'amortisation_calc_way', table: 'Товари', text: 'Не заповнений спосіб відобрадення витрат'},
        'response_person_id': {id: 'response_person_id', table: 'Товари', text: 'Не заповнений відповідальний'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(table_, 'МНМА', 'table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.warehouse_id = this.item.warehouse_id || null
      this.subdivision_id = this.item.subdivision_id || null
      this.table = []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа введення в експлуатацію МНМА № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        subdivision_id: this.subdivision_id,
        warehouse_id: this.warehouse_id,
        table: this.table
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              this.checkDocument(false, false)
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_LOW_COST_COMMISSIONING_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_table') {
                if (payload.answer) {
                  this.clearDocument()
                }
              }
            }
          }
      )
    }
  }
}
</script>
